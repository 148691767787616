import React from "react"
import { Link } from "gatsby"

import { routes } from "@data/routes"
import { socialMedia } from "@data/socialservices"

var d = new Date()
var n = d.getFullYear()

const text = {
  copyright: `© ${n} Chain SAS All rights reserved`,
}

export default function Footer() {
  return (
    <footer className="u-flex u-md-flexCol u-flexJustifyBetween u-flexAlignItemsEnd u-md-flexAlignItemsCenter">
      <nav
        className="u-flex u-md-flexCol u-md-flexAlignItemsCenter"
        style={{ "--column-gap": "1.2rem", "--row-gap": "2vh" }}
      >
        {routes.map(
          (route, i) =>
            route.isFooter && (
              <Link key={route + i} to={`/${route.slug}/`}>
                {route.page}
              </Link>
            )
        )}
      </nav>
      <div className="u-flex u-flexCol" style={{ "--row-gap": "1.2rem" }}>
        <div
          className="u-flex u-flexJustifyEnd u-md-flexJustifyCenter"
          style={{ "--column-gap": "1.2rem" }}
        >
          {socialMedia.map((el, i) => (
            <a key={i} href={el.url} target="_blank" rel="noreferrer">
              {el.name}
            </a>
          ))}
        </div>
        <p>{text.copyright}</p>
      </div>
    </footer>
  )
}
