import React from "react"
import Layout from "@components/Layout/layout"

import "./src/styles/global.css"
import "./src/styles/flex.css"
import "./src/styles/flex-sm.css"
import "./src/styles/flex-md.css"
import "./src/styles/flex-lg.css"
import "./src/styles/grid.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
