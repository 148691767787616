import React, { useState } from "react"
import { Link } from "gatsby"
import { Player } from "@lottiefiles/react-lottie-player"

import phone_button from "../../lotties/phone_button"

import "./header.css"
import Logotype from "../../svgs/logotype"
import { routes } from "@data/routes"

export default function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav
      className="u-flex u-flexAlignItemsCenter navigation"
      style={{ padding: "5vh 1.5vw" }}
    >
      <Link
        to="/"
        className="u-flexExpandRight"
        aria-label="Link to homepage of WeChain website"
      >
        <Logotype width={170} />
      </Link>
      <button
        aria-label="Mobile menu trigger"
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
        style={{ "--column-gap": "1.7rem" }}
      >
        {routes.map(
          (route, i) =>
            route.isHeader && (
              <Link
                key={route + i}
                to={`/${route.slug}/`}
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded)
                }}
              >
                {route.page}
              </Link>
            )
        )}
        <a href="tel:0369483019" className="phone_cta">
          Appelez-nous
          <Player
            autoplay
            speed={1}
            loop
            src={phone_button}
            controls={false}
            style={{ height: "120px" }}
          />
        </a>
      </div>
      <div
        className="u-flex u-flexExpandLeft"
        style={{ "--column-gap": "0.3rem" }}
      >
        <a href="tel:0369483019" className="u-flexExpandLeft tel_btn">
          <span style={{ fontSize: "0.9rem" }}>Appelez nous</span> au
          03.69.48.30.19
        </a>
        <a
          className="u-flexExpandLeft button sign_in_btn"
          href="https://app.wechain.eu/"
          target="_blank"
          rel="noreferrer"
        >
          Connexion
        </a>
      </div>
    </nav>
  )
}
