export const socialMedia = [
  {
    url: "https://twitter.com/@wechainapp",
    name: "Twitter",
    //icon: <Twitter />,
  },
  {
    url: "https://www.linkedin.com/company/we-chain/about/",
    name: "LinkedIn",
    //icon: <Instagram />,
  },
]
