export const routes = [
  {
    page: "Cas clients",
    slug: "cas-clients",
    isHeader: true,
    isFooter: true,
  },
  {
    page: "Contact",
    slug: "contact",
    isHeader: true,
    isFooter: true,
  },
  {
    page: "Tarifs",
    slug: "tarifs",
    isHeader: true,
    isFooter: true,
  },
  {
    page: "Blog",
    slug: "blog",
    isHeader: true,
    isFooter: true,
  },
  {
    page: "Terms",
    slug: "terms",
    isFooter: true,
  },
  {
    page: "Privacy",
    slug: "privacy",
    isFooter: true,
  },
  {
    page: "Qui sommes-nous ?",
    slug: "qui-sommes-nous",
    isHeader: true,
    isFooter: true,
  },
]
